<template>
  <div>
    <div>
      <Header></Header>
      <div class="bg">
        <img alt="Vue logo" class="logo" src="../assets/bg.png">
        <modularA v-if="currentIndex === 1" />
        <modularB v-if="currentIndex === 2" />
        <modularC v-if="currentIndex === 3" />
        <modularD v-if="currentIndex === 4" />
        <modularE v-if="currentIndex === 5" />
        <modularF v-if="currentIndex === 6" />
      </div>

      <div id="progressBar"
        class="fixed z-[99999] bottom-1/2 translate-y-1/2 transition-opacity duration-300 hidden md:block right-10 opacity-100"
        style="translate: none; rotate: none; scale: none; transform: translate(0px, 50%); bottom: 50%;">

        <div id="progressBarItems"
          class="rounded-full r-progress-bar-bg flex items-center justify-center   w-10 py-6   " style="opacity: 100;">

          <div class="flex flex-col justify-between relative progress-bar-box   w-4 h-[251px]   ">

            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" v-for="(item, index) in data"
              :key="item.title" @click="onBarDot(index)">
              <!-- <div class="w-1.5 h-1.5 rounded-full transition-colors duration-500 bg-opacity-100"
                :class="{ 'bg-white': currentIndex !== index, 'bg-img': currentIndex === index }">
              </div> -->

              <div class="w-1.5 h-1.5 rounded-full transition-colors duration-500 bg-opacity-100"
                :class="{ 'bg-white': currentIndex !== index }">
              </div>
              <img v-if="currentIndex === index" alt="" fetchpriority="high" loading="eager" width="20" height="20"
                decoding="async" data-nimg="1"
                class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 max-w-none transition-all duration-500   w-5 h-5   "
                style="color: transparent; top: 45px;" src="../assets/A1_penguin.png?tr:f-avif&amp;t=1">
            </div>

          </div>
        </div>
      </div>
      <div class="backTop"></div>

      <!-- <div id="progressBar"
        class="fixed z-[99999] bottom-1/2 translate-y-1/2 transition-opacity duration-300 hidden md:block right-10 opacity-100"
        style="translate: none; rotate: none; scale: none; transform: translate(0px, 50%); bottom: 50%;">
        <div id="progressBarItems"
          class="rounded-full r-progress-bar-bg flex items-center justify-center   w-10 py-6   " style="opacity: 100;">
          <div class="flex flex-col justify-between relative progress-bar-box   w-4 h-[251px]   ">
            <img alt="" fetchpriority="high" loading="eager" width="20" height="20" decoding="async" data-nimg="1"
              class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 max-w-none transition-all duration-500   w-5 h-5   "
              style="color: transparent; top: 45px;" src="../assets/A1_penguin.png?tr:f-avif&amp;t=1">
            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" @click="onClick('1')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-100"></div>
            </div>
            <div class="w-4 h-4 flex items-center justify-center current-progress-bar-item cursor-pointer"
              @click="onClick('2')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-30"></div>
            </div>
            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" @click="onClick('3')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-30"></div>
            </div>
            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" @click="onClick('4')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-30"></div>
            </div>
            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" @click="onClick('5')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-30"></div>
            </div>
            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" @click="onClick('6')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-30"></div>
            </div>
            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" @click="onClick('7')">
              <div class="w-1.5 h-1.5 rounded-full bg-white transition-colors duration-500 bg-opacity-30"></div>
            </div>

          </div>
        </div>
      </div> -->
    </div>

  </div>


</template>

<script>
import Header from './Header.vue'
import modularA from './modularA.vue'
import modularB from './modularB.vue'
import modularC from './modularC.vue'
import modularD from './modularD.vue'
import modularE from './modularE.vue'
import modularF from './modularF.vue'
import { debounce } from 'lodash';
// import Swiper from 'swiper'

export default {
  name: 'HelloWorld',
  components: {
    Header,
    modularA,
    modularB,
    modularC,
    modularD,
    modularE,
    modularF,
  },
  props: {

  },

  data () {
    this.onBarDot = debounce(this.onBarDot, 600, {
      leading: true
    })
    return {
      currentIndex: 0, // 当前选中
      lastScrollTime: 0,
      // 数组对象(展示图)
      data: [
        {
          title: 'img-1',
          imgUrl: ''
        },
        {
          title: 'img-2',
          imgUrl: ''
        },
        {
          title: 'img-3',
          imgUrl: ''
        },
        {
          title: 'img-4',
          imgUrl: ''
        },
        {
          title: 'img-5',
          imgUrl: ''
        },
        {
          title: 'img-6',
          imgUrl: ''
        },
        {
          title: 'img-7',
          imgUrl: ''
        }
      ]
    }
  },
  computed: {},
  mounted () {
    window.addEventListener('mousewheel', this.handleMousewheel);
    window.addEventListener('DOMMouseScroll', this.handleMousewheel);
    // new Swiper('.swiper-container', {
    //   pagination: '.swiper-pagination',
    //   direction: 'vertical',
    //   slidesPerView: 1,
    //   paginationClickable: true,
    //   spaceBetween: 30,
    //   mousewheelControl: true
    // });
  },
  beforeDestroy () {
    // 在组件销毁前移除事件监听
    window.removeEventListener('mousewheel', this.handleMousewheel);
    window.removeEventListener('DOMMouseScroll', this.handleMousewheel);
  },
  methods: {
    // onClick (index) {
    //   this.current = index
    //   console.log(index, '-------')
    // },
    onBarDot (index) {
      this.currentIndex = index;
      console.log(index, '-------')
    },
    // handleMousewheel(e) {
    //   e.preventDefault();
    //   if (isScrolling) return; // 如果正在滚动，则直接返回
    //     isScrolling = true; // 标记为正在滚动
    //   const delta = e.wheelDelta || -e.detail;
    //   if (delta > 0) {
    //     this.onBarDot((this.currentIndex + 1) % 6);
    //   } else {
    //     this.onBarDot((this.currentIndex - 1 + 6) % 6);
    //   }
    // },
    handleMousewheel(e) {
    e.preventDefault();
    const now = Date.now();
    // 设置滚动间隔（500毫秒）
    if (now - this.lastScrollTime < 500) {
      return; // 如果在500毫秒内滚动，则忽略此次事件
    }
    this.lastScrollTime = now;
    const delta = e.wheelDelta || -e.detail;
    if (delta > 0) {
      this.onBarDot((this.currentIndex - 1 + this.data.length) % this.data.length);
    } else {
      this.onBarDot((this.currentIndex + 1) % this.data.length);
    }
  }
    // handleMousewheel (e) {
    //   let isScrolling = false;
    //   e.preventDefault();
    //   if (isScrolling) return; // 如果正在滚动，则直接返回
    //   isScrolling = true; // 标记为正在滚动

    //   const delta = e.wheelDelta || -e.detail;
    //   this.onBarDot(delta > 0 ? (this.currentIndex + 1) % 7 : (this.currentIndex - 1 + 7) % 7);

    //   setTimeout(() => {
    //     isScrolling = false; // 恢复标记
    //   }, 300); // 300毫秒的间隔
    // }
  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg {
  inset: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  margin-top: 48px;
}

.logo {
  position: absolute;
  translate: none;
  rotate: none;
  scale: none;
  /* transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); */
  box-sizing: border-box;
  width: 100%;
  overflow: visible;
  z-index: 1;
}

.bg-img {
  background-image: url('@/assets/A1_penguin.png');
  background-size: 20px 20px;
}

#progressBar {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate(0px, 50%);
  float: right;
  margin-right: 41px;
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
}

.duration-300 {
  transition-duration: .3s;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
}

.opacity-100 {
  opacity: 1;
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
}

/* #progressBar {
  width: 48px;
  height: 392px;
  background: linear-gradient(0deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, .09));
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  overflow: hidden;
  float: right;
  margin-right: 41px;
  opacity: 100;
  border-radius: 9999px;
} */
.r-progress-bar-bg {
  background: linear-gradient(0deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, .09));
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-10 {
  width: 2.5rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.w-4 {
  width: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.h-\[251px\] {
  height: 251px;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.w-1\.5 {
  width: .375rem;
}

.h-1\.5 {
  height: .375rem;
}

.duration-500 {
  transition-duration: .5s;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
}

.bg-opacity-100,
.bg-white {
  --tw-bg-opacity: 1;
}

.bg-white {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.rounded-full {
  border-radius: 9999px;
}

/* #progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    transition: width 0.1s;
  } */
</style>
