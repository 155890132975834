<template>
  <div class="modularE">
    <div class="text">
      <img class='icon' src="../assets/A5_penguin.png">
      <div class="title">Contact Us</div>
      <div class="news">
        <span>
          Join the Giggle Penguin Kingdom
        </span>
        <div class="content">
          <img src="../assets/icon_Twitter.png" style="float: left;margin: 8px 12px 0 8px;"/>
          <div style="width: 70%; margin-top: 13px; float: left;">Follow the penguin to explore newworlds!</div>
          <img src="../assets/icon_arrow_r.png" style="margin: 8px 90%; cursor: pointer;"/>
        </div>
        <div class="content">
          <img src="../assets/icon_tg.png" style="float: left;margin: 8px 12px 0 8px;"/>
          <div style="width: 70%; margin-top: 13px; float: left;">Follow the penguin to explore newworlds!</div>
          <img src="../assets/icon_arrow_r.png" style="margin: 8px 90%; cursor: pointer;"/>
        </div>
        <div class="content">
          <img src="../assets/icon_youtube.png" style="float: left;margin: 8px 12px 0 8px;"/>
          <div style="width: 70%; margin-top: 13px; float: left;">Follow the penguin to explore newworlds!</div>
          <img src="../assets/icon_arrow_r.png" style="margin: 8px 90%; cursor: pointer;"/>
        </div>
        <div class="content">
          <img src="../assets/icon_email.png" style="float: left;margin: 8px 12px 0 8px;"/>
          <div style="width: 70%; margin-top: 13px; float: left;">Follow the penguin to explore newworlds!</div>
          <img src="../assets/icon_arrow_r.png" style="margin: 8px 90%; cursor: pointer;"/>
        </div>
      </div>
    </div>
    <img class="img" src="../assets/A5.png"/>
  </div>
</template>

<script>
export default {
  name: 'modularE',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .modularE {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 30;
  }
  .img {
    width: 685px;
    height: 660px;
    margin: 210px 0 0 330px;
    float: left;
  }
  .text {
    width: 375px;
    height: 542px;
    background: #fff;
    float: left;
    margin: 200px 0 0 176px;
    border-radius: 40px;
  }
  .icon {
    position: absolute;
    margin: -35px 248px;
  }
  .title{
    font-family: Fredoka Light;
    font-weight: 600;
    font-size: 20px;
    color: #2F9C36;
    margin: 21px 30px;    
  }
  .news {
    width: 315px;
    height: 592px;
    margin: 0 30px;
    border-top: 1px solid #DDDDDD;
  }
  span {
    font-family: Fredoka Light;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 40px;
  }
 .content {
    width: 315px;
    height: 79px;
    border-radius: 12px;
    border: 1px solid #E8E8E8;
    margin-bottom: 5%;
  }
 

</style>
