<template>
  <div class="modularC">
    <div class="text">
      <img class='icon' src="../assets/A3_penguin.png">
      <div class="title">Giggle Penguin Island</div>
      <div class="news">
        <h3>
          Quick English Learning
        </h3>
        <div class="content">
          Giggle mini-App is a English as second language learning game roots on Telegram. 
        </div>
        <h3>
          Fun for Kids 7-9
        </h3>
        <div class="content">
          Combining with Crypto vocabulary and phrases, aiming to provide 7-9 year old a proper and fun gateway to web3 world. 
        </div>
        <div class="content">
          Easy tasks, fun themes, good future!
        </div>
        <div class="button">
          OPERATIONS AND FUNDING
        </div>
      </div>
    </div>
    <img class="img" src="../assets/A3.png"/>
  </div>
</template>

<script>
export default {
  name: 'modularC',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .modularC {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 30;
  }
  h3 {
    font-family: Fredoka Light;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-top: 24px;
  }
  .img {
    width: 696px;
    height: 704px;
    margin: 178px 0 0 317px;
    float: left;
  }
  .text {
    width: 375px;
    height: 581px;
    background: #fff;
    float: left;
    margin: 207px 0 0 176px;
    border-radius: 40px;
  }
  .icon {
    position: absolute;
    margin: -35px 248px;
  }
  .title{
    font-family: Fredoka Light;
    font-weight: 600;
    font-size: 20px;
    color: #2F9C36;
    margin: 21px 30px;    
  }
  .news {
    width: 315px;
    height: 592px;
    margin: 0 30px;
    border-top: 1px solid #DDDDDD;;
  }
  span {
    font-family: Fredoka Light;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 40px;
    margin-bottom: 5px;
  }
  .content {
    font-family: Fredoka Light;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    word-break: keep-all;
    line-height: 24px;
    margin-bottom: 29px;
  }
  .button {
  width: 315px;
  height: 48px;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height:48px;
  text-align: center;
  margin-top: 64px;
}
 

</style>
