<template>
  <div class="modularB">
    <div class="text">
      <img class="icon" src="../assets/A2_penguin.png" />
      <div class="title">Tokenomics</div>
      <div class="news">
        <h3>Total Supply</h3>
        <span>10,000,000,000,000</span>
        <div class="content" v-for="item in data" :key="item.title">
          <div class="legend" :style="{ 'background': item.style }">
          </div>
          <span>0.3% Game share</span>
        </div>
        <div class="button">
          OPERATIONS AND FUNDING
        </div>
      </div>
    </div>
    <img class="img" src="../assets/A2.png" />
  </div>
</template>

<script>
export default {
  name: "modularB",
  props: {},
  data () {
    return {
      data: [
        {
          title: "img-1",
          style: "#50C557",
        },
        {
          title: "img-2",
          style: "#53B4FF",
        },
        {
          title: "img-3",
          style: "#BF73FF",
        },
        {
          title: "img-4",
          style: "#FFA031",
        },
        {
          title: "img-5",
          style: "#FF6B31",
        },
        {
          title: "img-6",
          style: "#FD4AC8",
        },
        {
          title: "img-7",
          style: "#07BD83",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularB {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
}

.img {
  width: 752px;
  height: 660px;
  margin: 210px 0 0 290px;
  float: left;
}

.text {
  width: 375px;
  height: 611px;
  background: #fff;
  float: left;
  margin: 207px 0 0 176px;
  border-radius: 40px;
}

.icon {
  position: absolute;
  margin: -35px 248px;
}

.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2f9c36;
  margin: 21px 30px;
}

.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #dddddd;
}

h3 {
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-top: 24px;
}

.content {
  height: 24px;
  margin-top: 20px
}

.legend {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 8px;
}

.button {
  width: 315px;
  height: 48px;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height:48px;
  text-align: center;
  margin-top: 64px;
}

span {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
}
</style>
