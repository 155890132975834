<template>
  <div class="modularF">
    <img class="img" src="../assets/A6.png"/>
    <div class="text">
      <img class='icon' src="../assets/A6_penguin.png">
      <div class="title">KidQuoraAI</div>
      <div class="news">
        <div style="margin-top: 16px; height: 212px;">
          <img src="../assets/icon_avatar.png" style="float: left;"/>
          <div class="dialogue">
            Hey there, young explorers!Welcome to Giggle Penguin lsland, where youcan chat with our super-smart penguin pal,Peppy! Peppy is here to answer all yourquestions, share fun facts, and even tell jokes.
          </div>
        </div>
        <div style="margin-top: 16px;height: 212px;">
          <img src="../assets/icon_avatar.png" style="float: left;"/>
          <div class="dialogue">
            Just type in what you want to say, and Peppywill chat right back with you. whether you'recurious about penguins,Web3, need help withyour homework, or just want to share a story,Peppy is ready to talk anytime.
          </div>
        </div>
        <div class="log">Let's start chatting and make some gigglestogether!</div>
        <div class="log">(i.g.) What is $PEPPY? Tell a goofy jokeabout PEPPY!</div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'modularF',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .modularF {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 30;
  }
  .img {
    width: 908px;
    height: 629px;
    margin: 219px 0 0 226px;
    float: left;
  }
  .text {
    width: 375px;
    height: 667px;
    background: #fff;
    float: left;
    margin: 218px 0 0 192px;
    border-radius: 40px;
  }
  .icon {
    position: absolute;
    margin: -35px 248px;
  }
  .title{
    font-family: Fredoka Light;
    font-weight: 600;
    font-size: 20px;
    color: #2F9C36;
    margin: 21px 30px;    
  }
  .news {
    width: 315px;
    height: 592px;
    margin: 0 30px;
    border-top: 1px solid #DDDDDD;;
  }
  span {
    font-family: Fredoka Light;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 40px;
    margin-bottom: 5px;
  }
  .dialogue {
    float: left;
    width: 253px;
    height: 189px;
    background: #F8F8F8;
    border-radius: 10px;
    border: 1px solid #DDDDDD;
    margin-left: 6px;
    padding: 11px;
    font-family: Fredoka Light;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .log {
    margin-left: 14%;
    font-family: Fredoka Light;
    font-weight: 400;
    font-size: 14px;
    color: #2F9C36;
    margin-top: 12px;
  }
 

</style>
