<template>
  <div class="head">
    <img class="logo" src="../assets/logo.png">
    <div class="box">
      <img class="icon1" src="../assets/icon_32_TG.png">
      <img class="icon2" src="../assets/icon_32_Tw.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .head {
    width: 100vw;
    height: 48px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }
  .logo {
      width: 273px;
      height: 48px;
      margin-left: 50px;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon1 {
      margin-right: 50px;
    }
    .icon2 {
      margin-right: 49px;
    }

</style>
