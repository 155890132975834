<template>
  <div class="modularA">
    <img class="img" src="../assets/A4.png" />
    <div class="text">
      <img class="icon" src="../assets/A4_penguin.png" />
      <div class="title">BUY Penguin</div>
      <div class="news">
        <div class="content">
          <div class="legend">
            <img src="../assets/icon_OKX.png" />
          </div>
          <div style="width: 80%; float: left">
            <h3>Low Fees Centralized</h3>
            OKX is a secure and reliable crypto exchange platform designed to
            give users the best trading experience.
            <div class="button">
              GO
            </div>
          </div>
        </div>

        <div class="content">
          <div class="legend">
            <img src="../assets/icon_PancakeSwap.png" />
          </div>
          <div style="width: 80%; float: left">
            <h3>Affordable Trading Decentralized</h3>
            PancakeSwap is a secure and widely-used decentralized exchange (DEX)on Binance Smart Chain, offering fast, low-0ost token siv, aps and yield faming opportunities.
            <div class="button">
            GO
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModularA",
  props: {},
  data() {
    return {
      // data: [
      //   {
      //     title: "img-1",
      //     src: '../assets/icon_OKX.png',
      //   },
      //   {
      //     title: "img-2",
      //     src: "../assets/icon_PancakeSwap.png",
      //   },
      // ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularA {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
}
.img {
  width: 704px;
  height: 738px;
  margin: 171px 0 0 320px;
  float: left;
}
.text {
  width: 375px;
  height: 551px;
  background: #fff;
  float: left;
  margin: 207px 0 0 321px;
  border-radius: 40px;
}
.icon {
  position: absolute;
  margin: -35px 248px;
}
.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2f9c36;
  margin: 21px 30px;
}
.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #dddddd;
}
span {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 5px;
}
.content {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  word-break: keep-all;
  height: 153px;
  margin-top: 29px;
  margin-bottom: 13px;
}
.legend {
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 8px;
}
h3 {
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0 0 14px 0;
}
.button {
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid #2F9C36;
  text-align: center;
  // opacity: 0.3;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 16px;
  color: #2F9C36;
  margin-left: 65%;
  margin-top: 3%;
}
</style>
