<template>
  <div class="modularA">
    <img class="img" src="../assets/A1.png" />
    <div class="text">
      <img class='icon' src="../assets/A1_penguin.png">
      <div class="title">Giggle Penguin Island</div>
      <div class="news">
        <span>
          Welcome to Giggle Penguin Kingdom!
        </span>
        <div class="content">
          Once upon a time, in the sparkling blue ocean, there was a tiny, sunny spot called Giggle Island. This wasn't
          just any island—it was home to the happiest penguins you'd ever meet! It’s Giggle Penguin Kingdom!
        </div>
        <div class="content">
          These weren’t ordinary penguins. Each penguin had a bright, colorful beak and rubbery cute body which make them energetic all day long. They lived together in cozy snow huts and spent their days sliding down snowy hills, splashing in the water, and playing hide and seek in the ice caves.
        </div>
        <div class="content">
          The king was $PEPPY. $PEPPY was the smallest penguin with the biggest smile and a BNB crown. Every morning, he would waddle around the island with a giggle, making sure everyone was ready for a day full of fun.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModularA',
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularA {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
}

.img {
  width: 993px;
  height: 618px;
  margin: 231px 0 0 176px;
  float: left;
}

.text {
  width: 375px;
  height: 667px;
  background: #fff;
  float: left;
  margin: 207px 0 0 176px;
  border-radius: 40px;
}

.icon {
  position: absolute;
  margin: -35px 248px;
}

.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2F9C36;
  margin: 21px 30px;
}

.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #DDDDDD;
  ;
}

span {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 5px;
}

.content {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  word-break: keep-all;
  line-height: 24px;
  margin-bottom: 17px;
}
</style>
